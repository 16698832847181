import NotificationNotifyShip from '../../resource_models/notification_notify_ship'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new NotificationNotifyShip(state.entities[id]))
}

export const find = state => id => {
  return new NotificationNotifyShip(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
