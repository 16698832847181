import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'notification_notify_ships',
  attributes: ['id', 'subject', 'content', 'created_at', 'updated_at'],
  editableAttributes: []
}

export default class NotificationNotifyShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
