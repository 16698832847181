<template lang="pug">
.vc-dashboard-resource-links
  a.resource-unit(
    v-for="resource in resourcesList"
    :href="resource.path"
    @click.prevent="resource.click"
  )
    .icon
      i.fa(:class="resource.icon")
    .text
      span {{ resource.name }}
    //- .count
    //-   span {{ resource.count }}
  a.resource-unit(@click="showBonusPointsBox")
    .icon
      i.fa.fa-product-hunt
    .text
      span {{ attributeLocaleText('user', 'bonus_credit') }}
</template>

<script>
import BonusPointsBox from './bonus-points-box.vue'
import NotifyList from './notify-list.vue'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      resourcesList: [
        {
          name: '通知總覽',
          icon: 'fa-newspaper-o',
          path: '#/user/notifications',
          click: this.openNotifyList
        },
        {
          name: '我的收藏',
          icon: 'fa-heart',
          path: '/user/collections',
          click: () => {
            Turbolinks.visit('/user/collections')
          }
        }
        // {
        //   name: '折價票券',
        //   icon: 'fa-ticket',
        //   path: '#/user/coupons',
        // },
      ]
    }
  },

  computed: {
    siteFeatureConfigs() {
      return this.$store.getters['siteConfigs/allConfigs'].site_feature_configs
    },

    oddPayEnabled() {
      return this.siteFeatureConfigs?.odd_pay?.enabled
    }
  },

  // created() {},
  async mounted() {
    await this.getFeatureSiteConfigs()

    if (this.oddPayEnabled)
      this.resourcesList.push({
        name: '訂閱內容',
        icon: 'fa-list',
        path: '/user/subscriptions',
        click: () => {
          Turbolinks.visit('/user/subscriptions')
        }
      })
  },

  methods: {
    showBonusPointsBox() {
      this.$buefy.modal.open({
        parent: this,
        component: BonusPointsBox,
        width: 450
      })
    },

    getFeatureSiteConfigs() {
      if (!this.siteFeatureConfigs)
        return this.$store.dispatch('siteConfigs/getSiteFeatureConfigs')
    },

    openNotifyList() {
      this.$buefy.modal.open({
        parent: this,
        component: NotifyList,
        width: 800
      })
    }
  }
}
</script>
