<template lang="pug">
.vc-dashboard-notify-list
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )
  .notification-title 通知列表
  .list-wrapper(
    v-for="(notification, index) in notifications"
    :key="index"
  )
    .title {{ notification.subject }}
    .body-wrapper
      .content {{ notification.content }}
      .time {{ timeAgoLocaleText(notification.updated_at) }}
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'

const store = useStore()
const notifications = computed(() => {
  return store.getters['notificationNotifyShips/all']
})
const isLoading = computed(() => {
  return store.getters['users/isLoading']
})

onMounted(
  async () =>
    await store.dispatch('users/fetchNotifyShips', {
      options: { sort: '-updated_at' }
    })
)
</script>
